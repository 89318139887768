export const useLevelsStore = defineStore('levels', () => {
    const { $getApi } = useNuxtApp();

    const levels = ref([]);
    const savedType = ref(null);

    const getLevels = async type => {
        let query = 'levels';

        if (savedType.value === type && levels.value.length > 0) {
            return;
        }

        if (type) {
            query += `?${type}`;
            savedType.value = type;
        }

        try {
            const res = await $getApi(query);
            levels.value = res.data?.data;
        } catch {
            levels.value = [];
        }
    };

    return { levels, getLevels };
});
